import * as Keys from "./keys"


export class LabelClassHelper{
    static getLabelClassReservationState(state) {
        switch (state) {
            case Keys.RESERVATION_STATES.PENDING:
                return "label-warning";
            case Keys.RESERVATION_STATES.COMPLETED:
                return "label-success";
            case Keys.RESERVATION_STATES.CANCELED:
                return "label-danger";
            case Keys.RESERVATION_STATES.PAYMENT_REQUIRED:
                return "label-warning";
        }
    }
} 
