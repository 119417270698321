<template>
    <div>
        <Header></Header>
        <div class="container-full">
            <div class="row">
                <div class="col-md-3 left-column collapse hide-print" id="left-menu">
                    <SideMenu></SideMenu>
                </div>
                <div class="col-xs-12 col-md-9 right-column">
                    <div
                            v-show="$route.params.errorMessage"
                            class="alert alert-danger"
                            style="margin:10px;"
                            role="alert"
                    >
                        {{ $route.params.errorMessage }}
                    </div>
                    <div
                            v-show="$route.params.successMessage"
                            class="alert alert-success"
                            style="margin:10px;"
                            role="alert"
                    >
                        {{ $route.params.successMessage }}
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
        <SpinnerLoader v-show="loading"></SpinnerLoader>
    </div>
</template>

<script>
    import Header from "../common/Header";
    import SideMenu from "../common/SideMenu";
    import SpinnerLoader from "../common/SpinnerLoader";

    export default {
        name: "ContainerWithMenu",
        components: { Header, SideMenu, SpinnerLoader },
        props:{
            loading: {
                type: Boolean,
                default: false
            }
        }
    };

</script>
