<template>
    <Paginate
            v-show="pageCount > 1"
            :page-count="pageCount"
            :page-range="3"
            :click-handler="clickHandler"
            :prev-text="$t('pagination.prev')"
            :next-text="$t('pagination.next')"
            :container-class="'pagination'"
            :page-class="'page-item'">
    </Paginate>
</template>

<script>
    import Paginate from "vuejs-paginate/src/components/Paginate";
    export default {
        name: "Pagination",
        components: {Paginate},
        props: {
            clickHandler: {
                type: Function,
                default: () => { }
            },
            pageCount: {
                type: Number,
                required: true
            },
        }
    }
</script>