import { StringsHelper } from "./stringsHelper"
import {LabelClassHelper} from "./labelClassHelper"
import { Utils } from "./utils"
import * as Keys from "./keys"
import {LIMIT_RECORDS_API} from "./constants"

export default {
    created () {
    },
    data: function () {
      return {
        StringsHelper: StringsHelper,
        LabelClassHelper: LabelClassHelper,
        Keys: Keys,
        Utils: Utils,
        defaultLimit: LIMIT_RECORDS_API,
        countElements: 0
      }
    },
    computed: {
      pagesElements: function () {
          return Utils.getNumberPageByCountLimit(this.countElements, this.defaultLimit)
      }
    }
}
