<template>
	<div id="side-menu">
		<div class="visible-xs visible-sm sidebar-close">
			<button type="button" class="close" data-toggle="collapse" data-target="#left-menu"><i class="las la-times"></i></button>
		</div>
		<div id="section-reservation" v-if="hasReservationPermission">
			<h4 class="margin-bottom-10">Prenotazioni</h4>
			<ul class="nav nav-pills nav-stacked">
				<li role="presentation">
					<router-link to="/booking">
						<i class="las la-calendar-plus"></i>
						Nuova Pronotazione
					</router-link>
				</li>
				<li role="presentation">
					<router-link to="/reservations">
						<i class="las la-calendar-check"></i>
						Prenotazioni
					</router-link>
				</li>
			</ul>
		</div>
		<div class="margin-top-40" id="section-administration">
			<h4 class="margin-bottom-10">Amministrazione</h4>
			<ul class="nav nav-pills nav-stacked">
				<li id="cash-report-link" role="presentation" v-if="hasReservationPermission">
					<router-link to="/cash-report">
						<i class="las la-cash-register"></i>
						Riepilogo Cassa
					</router-link>
				</li>
				<li id="transaction-link" role="presentation" v-if="hasAdministrationPermission">
					<router-link to="/transactions">
						<i class="las la-receipt"></i>
						Transazioni
					</router-link>
				</li>
				<li id="fee-log-link" role="presentation" v-if="hasAdministrationPermission">
					<router-link to="/fee-log">
						<i class="las la-book"></i>
						Registro dei Corrispettivi
					</router-link>
				</li>
				<li id="analytics-link" role="presentation" v-if="hasAdministrationPermission">
					<router-link to="/analytics">
						<i class="las la-chart-bar"></i>
						Statistiche
					</router-link>
				</li>
			</ul>
		</div>
		<ul class="nav nav-pills nav-stacked pushed-down" v-if="user">
			<li role="presentation">
				<a href="" @click.prevent.stop="logout" id="logout_button">
					<i class="las la-door-open"></i>
					Logout
				</a>
			</li>
			<li role="presentation" class="disabled" style="padding-left:30px;">
				<a href="#">
					v1.3
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	import {StorageHelper} from "../../common/storageHelper";
	import baseMixin from '@/common/baseMixin'

	export default {
		name: "SideMenu",
		mixins: [baseMixin],
		computed: {
			// a computed getter
			hasAdministrationPermission: function () {
				//TODO check permissions what show
				return this.user.role == this.Keys.USER_ROLE.ADMIN || this.user.role == this.Keys.USER_ROLE.ACCOUNTING 
			},
			hasReservationPermission: function () {
				//TODO check permissions what show
				return this.user.role == this.Keys.USER_ROLE.ADMIN || this.user.role == this.Keys.USER_ROLE.TICKET 
			},
			user: function () {
				return StorageHelper.getUser()
			}
		},
		methods: {
			logout() {
				StorageHelper.setToken(null);
				StorageHelper.setUser(null);
				this.$router.push({ name: "login" });
			}
		}
	};
</script>
